import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const widgetSlice = createSlice({
  name: "address",
  initialState: {
    slides: [],
    categories: [],
    contents: [],
    brands: [],
  },
  reducers: {
    setSlides(state, action) {
      state.slides = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setContents(state, action) {
      state.contents = action.payload;
    },
    setBrands(state, action) {
      state.brands = action.payload;
    },
  },
});

export const getContents = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(Global.API_URL + "Content/GetAllContents", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
      });

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log("Something went wrong!")
    }
  };
};

export const { setSlides, setCategories, setContents, setBrands } =
  widgetSlice.actions;
export default widgetSlice.reducer;
