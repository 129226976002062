import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function HeaderSearch() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const Search = () => {
    navigate("/search/" + searchTerm + "/3cols/1");
  };

  function onSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <div className="header-search header-search-extended header-search-visible d-none d-lg-block">
      <button className="search-toggle">
        <i className="icon-search"></i>
      </button>

      <form
        action="#"
        method="get"
        onSubmit={Search}
      >
        <div className="header-search-wrapper search-wrapper-wide">
          <button className="btn btn-primary" type="submit">
            <i className="icon-search"></i>
          </button>
          <label htmlFor="q" className="sr-only" value={searchTerm} required>
            Search
          </label>
          <input
            type="text"
            onChange={onSearchChange}
            value={searchTerm}
            className="form-control"
            name="q"
            placeholder="Search product ..."
            required
          />
        </div>
      </form>
    </div>
  );
}

export default HeaderSearch;
