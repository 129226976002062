export const cartPriceTotal = (cartItems) => {
  return cartItems.reduce((acc, cur) => {
    if(cur.discount===0){
      return acc + (cur.price * cur.quantity);
    }else{
      return acc + (cur.saleprice * cur.quantity);
    }
   
  }, 0);
};

export const cartPriceTotalBeforeDiscount = (cartItems) => {
  return cartItems.reduce((acc, cur) => {
    return acc + (cur.price * cur.quantity);
   
  }, 0);
};

export const cartQtyTotal = (cartItems) => {
  return cartItems.reduce((acc, cur) => {
    return acc + parseInt(cur.quantity, 10);
  }, 0);
};

export const isInCart = (cartItems, product) => {
  return cartItems.find((item) => item.id === product.id) ? true : false;
};

export const canAddToCart = (cartItems, variation, qty) => {
  let find = cartItems.find((item) => item.id === variation.id);
  if (find) {
    if (variation.stock === 0 || variation.stock < find.qty + qty) return false;
    else return true;
  } else {
    if (variation.stock === 0 || variation.stock < qty) return false;
    else return true;
  }
};

export const isInWishlist = (wishlist, product) => {
  return (
    product && wishlist.findIndex((item) => item.id === product.id) > -1
  );
};

export const isInCompare = (compare, product) => {
  return product && compare.findIndex((item) => item.slug === product.slug) > -1;
};

export const isSafariBrowser = function () {
  let sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Safari") !== -1 && sUsrAg.indexOf("Chrome") === -1)
    return true;
  return false;
};

export const isEdgeBrowser = function () {
  let sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Edge") > -1) return true;
  return false;
};

export const getIndex = function (element) {
  let children = element.parentElement.children;
  for (let i = 0; i < children.length; i++) {
    if (element === children[i]) return i;
  }

  return 0;
};

export const catFilter = function (products = [], category, flag = false) {
  if (category[0] === "All") return products;

  return products.filter((item) => {
    for (let i = 0; i < category.length; i++) {
      if (item.category.find((cat) => cat.slug === category[i])) {
        if (!flag) return true;
      } else {
        if (flag) return false;
      }
    }

    if (flag) return true;
    else return false;
  });
};

export const attrFilter = function (products = [], attr) {
  // if(products)
  return products.filter((item) => {
    if (attr === "all") {
      return true;
    }

    if (attr === "sale" && item.sale_price) {
      return true;
    }

    if (attr === "rated" && item.ratings > 3) {
      return true;
    }

    if (attr === "until" && item.until) {
      return true;
    }

    return item[attr] === true;
  });
};

export const scrollToPageContent = function () {
  let to = document.querySelector(".page-content").offsetTop - 74;
  if (isSafariBrowser() || isEdgeBrowser()) {
    let pos = window.pageYOffset;
    let timerId = setInterval(() => {
      if (pos <= to) clearInterval(timerId);
      else {
        window.scrollBy(0, -120);
        pos -= 120;
      }
    }, 1);
  } else {
    window.scrollTo({
      top: to,
      behavior: "smooth",
    });
  }
};

export const hideMobileMenu = function (){
  document.querySelector("body").classList.remove("mmenu-active");
}

export const parallax = () => {
  let parallax = document.querySelectorAll(".bg-parallax");

  for (let i = 0; i < parallax.length; i++) {
    let y = 0;
    if (parallax[i].classList.contains("header-parallax")) {
      y = ((10 - window.pageYOffset) * 47) / 900 + 50;
    } else {
      y =
        ((parallax[i].offsetTop - window.pageYOffset) * 47) /
          parallax[i].offsetTop +
        50;
    }

    parallax[i].style.backgroundPositionY = y + "%";
  }
};

export const countTo = function () {
  let items = document.querySelectorAll(".count");

  if (items) {
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let amount =
        parseInt(item.getAttribute("data-to"), 10) -
        parseInt(item.getAttribute("data-from"), 10);
      let time = parseInt(item.getAttribute("data-speed"), 10);
      let interval = parseInt(item.getAttribute("data-refresh-interval"), 10);
      let pt = 0;
      let height = item.parentElement.parentElement.parentElement.offsetTop;
      let flag = 0;

      document.addEventListener("scroll", countToScrollHandler, true);

      function countToScrollHandler() {
        if (pt <= time && height >= window.pageYOffset) {
          if (0 === flag) {
            let timerId = setInterval(() => {
              if (pt >= time) {
                clearInterval(timerId);
              }

              item.innerHTML = parseInt((pt * amount) / time);
              pt = pt + interval;
            }, interval);
          }

          flag = 1;
        }
      }
    }
  }
};

export function safeContent(html) {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

  // Removing the <script> tags
  while (SCRIPT_REGEX.test(html)) {
    html = html.replace(SCRIPT_REGEX, "");
  }

  // Removing all events from tags...
  html = html.replace(/ on\w+="[^"]*"/g, "");

  return {
    __html: html,
  };
}
