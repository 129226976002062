import React from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedParent } from "../../../store/slices/menu-slice";
import ALink from "../../features/alink";

function Footer() {
  const { contents } = useSelector((state) => state.widget);
  const dispatch = useDispatch();
  // const navigate=useNavigate()

  return (
    <footer className="footer">
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="widget widget-about">
                <h4 className="widget-title">About SIS</h4>
                <p>
                  At SIS "Small in size", we aim to set trends, through
                  fashionable, magical and unique girl's clothes. Here to make
                  your shopping experience lovely and enjoyable.
                </p>

                <div className="social-icons">
                  <a
                    href="https://www.facebook.com/SIS.BrandOfficial/"
                    className="social-icon"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/smallinsize.ae/"
                    className="social-icon"
                    title="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-instagram"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Useful Links</h4>

                <ul className="widget-list">
                  {window.location.pathname === "/" ? (
                    <li
                      onClick={() => {
                        window.scrollTo(0, 0);
                        dispatch(setSelectedParent(0));
                      }}
                    >
                      <ALink href="/">Home</ALink>
                    </li>
                  ) : (
                    <li onClick={() => dispatch(setSelectedParent(0))}>
                      <ALink href="/">Home</ALink>
                    </li>
                  )}

                  <li>
                    <ALink href="/contact">Contact us</ALink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Customer Service</h4>
                <ul className="widget-list">
                  {contents &&
                    contents.map((content, index) => {
                      return (
                        <li key={index}>
                          <ALink
                            href={
                              "/page/" +
                              content.Title.trim().replace(/ /g, "-") +
                              "/" +
                              content.ContentId
                            }
                          >
                            {content.Title}
                          </ALink>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="widget">
                <h4 className="widget-title">Get in touch</h4>

                <ul className="widget-list">
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-home"></i>
                    </span>
                    <ALink>
                      Mazaya Business Avenue Jumeirah Lakes Tower Office AA1
                    </ALink>
                  </li>
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-envelope"></i>
                    </span>
                    <a href="mailto:customercare@smallinsize.com">
                      customercare@smallinsize.com
                    </a>
                  </li>
                  <li>
                    <span className="margin-right-10">
                      <i className="icon-phone"></i>
                    </span>
                    <a href="tel:+971505013003">+971 50 5013003</a>
                    {/* <ALink href="tel:+971505013003"></ALink> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <figure className="footer-payments">
            <img
              src="/assets/images/visa.svg"
              alt="Payment methods"
              width="30"
              height="30"
            />
            <img
              src="/assets/images/mastercard.svg"
              alt="Payment methods"
              width="30"
              height="30"
            />
          </figure>
          <ALink href="/" className="logo">
            <img
              src="/assets/images/SISlogo.png"
              alt="SIS Logo"
              onClick={() => dispatch(setSelectedParent(0))}
            />
          </ALink>
          {/* <img
            src="/assets/images/SISlogo.png"
            alt="Footer Logo"
            width="82"
            height="25"
            onClick={()=>navigate("/")}
          /> */}

          <p className="footer-copyright">
            Copyright © {new Date().getFullYear()} SIS Store. All Rights
            Reserved. Powered by Vision & More.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
