import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "9000",
  },
};

const SizeChartModal = ({
  show,
  onHide,
  chartSizes,
  chartMeasurements,
  chartValues,
}) => {
  function closeModal() {
    if (document.querySelector(".ReactModal__Content")) {
      document
        .querySelector(".ReactModal__Content")
        .classList.remove("ReactModal__Content--after-open");
    }

    if (document.querySelector(".ReactModal__Overlay")) {
      document.querySelector(".ReactModal__Overlay").style.opacity = "0";
    }

    setTimeout(() => {
      onHide();
    }, 250);
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      className="container quickView-container"
      overlayClassName="d-flex align-items-center justify-content-center"
      shouldReturnFocusAfterClose={false}
      closeTimeoutMS={100}
      contentLabel="QuickView"
      style={customStyles}
      id="product-quickview"
    >
      <div className="modal-content">
        <div className="quickView-content horizontal skeleton-body">
          <div className="row skel-pro-single skel-quickview mb-0 loaded">
            <div className="col-lg-12 p-0 pr-lg-2 mb-2 mb-lg-0">
              <div>
                <p className="margin-bottom-20">
                  <strong>Find the best size!</strong>
                </p>
                <p className="margin-bottom-20">
                  When comparing your measurements to those listed on the site,
                  make sure to check if the fabric is stretchy or not. If it is,
                  it can stretch an inch or more. &nbsp;
                </p>
                <p className="margin-bottom-20">
                  Bust: Wrap the measuring tape loosely around the fullest part
                  of your bust to ensure a comfortable fit.&nbsp;
                </p>
                <p className="margin-bottom-20">
                  Waist: Measure the narrowest part of your natural waist,
                  holding the measuring tape loose enough to fit
                  comfortably.&nbsp;
                </p>
                <p className="margin-bottom-20">
                  Hips: Drape the measuring tape around the widest part of your
                  hips, resting the tape comfortably against your body.
                </p>
                <p className="margin-bottom-20">
                  Length: For tops and dresses, measure from the top of your
                  shoulder to the hem. For strapless dresses, measure from the
                  highest part of the neckline to the hem. For pants and skirts,
                  measure from the top of the waist to the hem.&nbsp;
                </p>
                <p className="margin-bottom-20">
                  Sleeve: With your arm outstretched, measure from the base of
                  your neck to the end of the sleeve hem to determine where the
                  sleeve length will fall on you.
                </p>
              </div>
              <hr />
              <div>
                <h5 className="text-center"> Size Chart</h5>
                <div className="table-responsive">
                  <table className="table-body-responsive">
                    <tbody>
                      <tr>
                        <td className="text-center bg-row-head">SIZE</td>
                        {chartMeasurements.map((measurement, index) => {
                          return (
                            <td className="text-center bg-row-head" key={index}>
                              {measurement}
                            </td>
                          );
                        })}
                      </tr>
                      {chartSizes.map((size, sindex) => {
                        return (
                          <tr key={sindex}>
                            <td className="text-center">{size}</td>
                            {chartMeasurements.map((measurement, mindex) => {
                              return (
                                <td className="text-center" key={mindex}>
                                  {chartValues.some(
                                    (value) =>
                                      value.SizeName === size &&
                                      value.Measurement === measurement
                                  )
                                    ? chartValues.filter(
                                        (value) =>
                                          value.SizeName === size &&
                                          value.Measurement === measurement
                                      )[0].Value
                                    : "-"}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <p className="margin-top-2">
                <b>Attention:</b> This chart is for reference only, sizes may be
                off by a few centimeters.
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={closeModal}
      >
        <span>×</span>
      </button>
    </Modal>
  );
};

export default SizeChartModal;
