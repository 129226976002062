const { createSlice } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderItems: [],
    orderNumber: "",
    orderBody: "",
    shippingCost: 0,
  },
  reducers: {
    addOrder(state, action) {
      return (state = {
        orderNumber: action.payload.orderNumber,
        orderItems: action.payload.orderItems,
        orderBody: action.payload.orderBody,
        shippingCost: action.payload.shippingCost,
      });
    },
    deleteOrder(state) {
      state.orderBody = "";
    },
  },
});

export const {
  addOrder,
  deleteOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
