import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from "react-modal";
import { Global } from "../../../Global";
import cogoToast from "cogo-toast";
import ReactGA from "react-ga";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "9001",
  },
};

Modal.setAppElement("body");

function NewsletterModal() {
  const [open, setOpen] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [email, setEmail] = useState("");

  const isLogged = useSelector((state) => state.credential.isLogged);

  const isValid =
    email.trim() !== "" && email.includes("@") && email.includes(".");

  const Subscribe = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "Email Added From Popup Modal",
      action: "Click",
      label: email,
    });

    if (!isValid) {
      cogoToast.error("Enter a valid email", { position: "bottom-left" });
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "NewsLetter/SubscribeToNewsLetter",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const result = await response.json();

        cogoToast.success(result, {
          position: "bottom-left",
        });
        setEmail("");
      } catch (error) {
        console.log("Something went wrong!")
      }
    }
  };

  useEffect(() => {
    const fetchSlider = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Slider/GetSlidesBySliderId/3",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const result = await response.json();
        setSliderData(result[0].slides);
        if (result[0].slides.length > 0) {
          sessionStorage.setItem(`hideNewsletter`, "true");
          setTimeout(() => {
            setOpen(true);
          }, 2500);
        }
      } catch (error) {
        console.log("Something went wrong!")
      }
    };

    if (
      sessionStorage.getItem("hideNewsletter") === null ||
      sessionStorage.getItem("hideNewsletter") === "false"
    ) {
      fetchSlider();
    }
  }, []);

  function closeModal(e) {
    document
      .getElementById("newsletter-popup-form")
      .classList.remove("ReactModal__Content--after-open");

    if (document.querySelector(".ReactModal__Overlay")) {
      document.querySelector(".ReactModal__Overlay").style.opacity = "0";
    }
    sessionStorage.setItem(`hideNewsletter`, "true");

    setOpen(false);
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      shouldReturnFocusAfterClose={false}
      contentLabel="Newsletter Modal"
      className="container newsletter-popup-container h-auto"
      overlayClassName="d-flex align-items-center justify-content-center"
      id="newsletter-popup-form"
    >
      <div className="modal-content overflow-hidden">
        <div className="row justify-content-center position-relative">
          <div className="col-12">
            <div className="row no-gutters bg-white newsletter-popup-content">
              {!isLogged ? (
                <Fragment>
                  <div className="col-lg-7 banner-content-wrap">
                    <div className="banner-content">
                      <img
                        src={
                          window.innerWidth > 991
                            ? "/assets/images/SISlogo.png"
                            : sliderData.length > 0
                            ? Global.PHOTO_URL + sliderData[0].SlideImage
                            : ""
                        }
                        alt="logo"
                        className="logo"
                        width="100"
                        height="100"
                      />
                      <form
                        onSubmit={(e) => Subscribe(e)}
                        className={
                          window.innerWidth > 991 ? "" : "margin-top-20"
                        }
                      >
                        <label>Enter Your Email Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          required
                        />
                        <div className="text-center">
                          <button
                            className="btn btn-outline-dark-2"
                            type="submit"
                          >
                            <span>subscribe</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-5 d-none d-lg-block">
                    <div className="lazy-overlay"></div>
                    {sliderData.length > 0 && (
                      <LazyLoadImage
                        alt="newsletter"
                        src={Global.PHOTO_URL + sliderData[0].SlideImage}
                        threshold={0}
                        width={396}
                        height={420}
                        effect="blur"
                        className="newsletter-img"
                      />
                    )}
                  </div>
                </Fragment>
              ) : (
                <div className="col-12">
                  <div className="lazy-overlay"></div>
                  {sliderData.length > 0 && (
                    <LazyLoadImage
                      alt="newsletter"
                      src={Global.PHOTO_URL + sliderData[0].SlideImage}
                      threshold={0}
                      width={396}
                      height={420}
                      effect="blur"
                      className="newsletter-img"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={closeModal}
          >
            <span>×</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default NewsletterModal;
