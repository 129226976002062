import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import React, { useEffect, useState } from "react";
import { Global } from "../../../Global";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

import OwlCarousel from "../../features/owl-carousel";
import ProductTwelve from "../../features/products/product-twelve";

import { productSlider } from "../../../utils/data";

function TrendyCollection() {
  const [firstProducts, setFirstProducts] = useState([]);
  const [secondProducts, setSecondProducts] = useState([]);

  const [isLoadingFirstProducts, setIsLoadingFirstProducts] = useState(true);
  const [isLoadingSecondProducts, setIsLoadingSecondProducts] = useState(true);

  const [firstWidgetName, setFirstWidgetName] = useState("");
  const [secondWidgetName, setSecondWidgetName] = useState("");

  const isLogged = useSelector((state) => state.credential.isLogged);
  const { currencyId } = useSelector((state) => state.currency);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const fetchProductsForFirstWidget = async () => {
      const offset = new Date().getTimezoneOffset();
      let groupId = 2;
      if (!isLogged) {
        groupId = 1;
      }

      try {
        const response = await fetch(
          Global.API_URL +
            "Widgets/GetProductsByWidgetId/8/Offset/" +
            offset +
            "/Country/237/GroupId/" +
            groupId +
            "/Currency/" +
            currencyId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              // "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const result = await response.json();
        setFirstWidgetName(result.WidgetName);
        setFirstProducts(result.Products);
        setIsLoadingFirstProducts(false);
      } catch (error) {
        console.log("Something went wrong!");
      }

      try {
        const response = await fetch(
          Global.API_URL +
            "Widgets/GetProductsByWidgetId/9/Offset/" +
            offset +
            "/Country/237/GroupId/" +
            groupId +
            "/Currency/" +
            currencyId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const result = await response.json();
        setSecondWidgetName(result.WidgetName);
        setSecondProducts(result.Products);
        setIsLoadingSecondProducts(false);
      } catch (error) {
        console.log("Something went wrong!");
      }
    };
    fetchProductsForFirstWidget();
  }, [isLogged, currencyId]);

  return (
    <div className="container trendy-products">
      <Tabs selectedTabClassName="show" defaultIndex={0}>
        <div className="heading heading-center margin-bottom-3">
          <h2 className="title">Trending</h2>

          <TabList
            className="nav nav-pills justify-content-center"
            role="tablist"
          >
            <Tab className="nav-item">
              <span
                className="nav-link"
                onClick={() =>
                  ReactGA.event({
                    category: "Trending Widget",
                    action: "Click",
                    label: firstWidgetName,
                  })
                }
              >
                {firstWidgetName}
              </span>
            </Tab>

            <Tab className="nav-item">
              <span
                className="nav-link"
                onClick={() =>
                  ReactGA.event({
                    category: "Trending Widget",
                    action: "Click",
                    label: firstWidgetName,
                  })
                }
              >
                {secondWidgetName}
              </span>
            </Tab>
          </TabList>
        </div>

        <div className="tab-content tab-content-carousel">
          <TabPanel>
            {isLoadingFirstProducts ? (
              <OwlCarousel
                adClass="owl-simple carousel-equal-height carousel-with-shadow"
                options={productSlider}
                isTheme={false}
              >
                {[1, 2, 3, 4, 5, 6].map((item, index) => (
                  <div className="skel-pro" key={index}></div>
                ))}
              </OwlCarousel>
            ) : (
              <OwlCarousel
                adClass="owl-simple carousel-equal-height carousel-with-shadow"
                options={productSlider}
                isTheme={false}
                type=""
              >
                {firstProducts.slice(0, 5).map((item, index) => (
                  <ProductTwelve product={item} key={index} />
                ))}
              </OwlCarousel>
            )}
          </TabPanel>
          <TabPanel>
            {isLoadingSecondProducts ? (
              <OwlCarousel
                adClass="owl-simple carousel-equal-height carousel-with-shadow"
                options={productSlider}
                isTheme={false}
              >
                {[1, 2, 3, 4, 5, 6].map((item, index) => (
                  <div className="skel-pro" key={index}></div>
                ))}
              </OwlCarousel>
            ) : (
              <OwlCarousel
                adClass="owl-simple carousel-equal-height carousel-with-shadow"
                options={productSlider}
                isTheme={false}
                type=""
              >
                {secondProducts.slice(0, 5).map((item, index) => (
                  <ProductTwelve product={item} key={index} />
                ))}
              </OwlCarousel>
            )}
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
}

export default TrendyCollection;
