import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/fonts-molla.min.css";
import "./assets/line-awesome/css/line-awesome.min.css";
import ReactGA from 'react-ga';

const container = document.getElementById('root');
const root = createRoot(container);
ReactGA.initialize("GTM-NJWTN9P")
root.render(
    <Provider store={store}>
      <PersistProvider>
        <App />
      </PersistProvider>
    </Provider>
);