import React, { useState } from "react";
import Modal from "react-modal";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import cogoToast from "cogo-toast";
import { Global } from "../../../Global";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCredential } from "../../../store/slices/credential-slice";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import ReactGA from "react-ga";
import ALink from "../alink";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(77,77,77,0.6)",
    zIndex: "9000",
  },
};

Modal.setAppElement("body");

const LoginModal = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();

  const isRegisterValid =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    email.trim() !== "" &&
    password.trim() !== "";

  const isLoginValid = email.trim() !== "" && password.trim() !== "";
  const isValidEmail = email.includes(".") && email.includes("@");
  let strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const isValidPassword = strongPasswordRegex.test(password);

  const Register = async (e) => {
    e.preventDefault();
    if (!isRegisterValid) {
      cogoToast.error("Fill all required fields", { position: "bottom-left" });
    } else if (!isValidEmail) {
      cogoToast.error("Enter a valid email", { position: "bottom-left" });
    } else if (!isValidPassword) {
      cogoToast.error(
        "Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and symbols",
        { position: "bottom-left" }
      );
    } else if (!agree) {
      cogoToast.warn("Please agrre to our terms and conditions", {
        position: "bottom-left",
      });
    } else {
      setDisable(true);
      ReactGA.event({
        category: "Customer Registration",
        action: "click",
        label:
          "Customer with name" +
          firstName +
          " " +
          lastName +
          "register using email" +
          email,
      });
      const respose = await fetch(
        Global.API_URL + "Customer/CustomerRegistration",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
          body: JSON.stringify({
            email: email.trim().toLowerCase(),
            password: password,
            firstName: firstName,
            lastName: lastName,
          }),
        }
      );
      const customerData = await respose.json();
      if (customerData.Code === 0) {
        cogoToast.success(customerData.Message, { position: "bottom-left" });
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setAgree(false);
        closeModal();
        navigate("/");
      } else {
        cogoToast.error(customerData.Message, { position: "bottom-left" });
      }
      setDisable(false);
    }
  };

  const Login = async (e) => {
    e.preventDefault();

    if (!isLoginValid) {
      cogoToast.error("Fill all required fields", { position: "bottom-left" });
    } else if (!isValidEmail) {
      cogoToast.error("Enter a valid email", { position: "bottom-left" });
    } else {
      ReactGA.event({
        category: "Customer Login",
        action: "click",
        label: "Customer login using email" + email,
      });
      try {
        const response = await fetch(
          Global.API_URL + "Customer/CustomerLogin",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
            body: JSON.stringify({
              email: email.trim().toLowerCase(),
              password: password,
              registrationTypeId: 1,
              firstName: "",
              lastName: "",
              profilePicture: "",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const result = await response.json();

        if (result.Code === 0) {
          cogoToast.success(result.Message, { position: "bottom-left" });
          dispatch(
            setCredential({
              isLogged: true,
              guestId: "",
              customerId: result.Response.CustomerId,
              customerToken: result.Response.CustomerToken,
              firstName: result.Response.FirstName,
              lastName: result.Response.LastName,
              email: result.Response.Email,
              purchasedProducts: result.Response.purchasedProducts,
            })
          );
          navigate("/dashboard");
        } else if (result.Code === -1 || result.Code === -2) {
          cogoToast.error(result.Message, { position: "bottom-left" });
        } else if (result.Code === -3) {
          cogoToast.error(result.Message, { position: "bottom-left" });
          navigate("/reset-password/" + email);
        } else {
          cogoToast.error(result.Message, { position: "bottom-left" });
        }
      } catch (error) {
        console.log("Something went wrong!")
      }
    }
  };

  const LoginByGoogle = async (data) => {
    ReactGA.event({
      category: "google Login",
      action: "click",
      label: "Customer login using email" + email,
    });
    try {
      const response = await fetch(Global.API_URL + "Customer/CustomerLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          password: password,
          registrationTypeId: 2,
          firstName: data.profileObj.givenName,
          lastName: data.profileObj.familyName,
          profilePicture: data.profileObj.imageUrl,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const result = await response.json();
      if (result.Code === 0) {
        cogoToast.success(result.Message, { position: "bottom-left" });
        dispatch(
          setCredential({
            isLogged: true,
            guestId: "",
            customerId: result.Response.CustomerId,
            customerToken: result.Response.CustomerToken,
            firstName: result.Response.FirstName,
            lastName: result.Response.LastName,
            email: result.Response.Email,
            purchasedProducts: result.Response.purchasedProducts,
          })
        );
      } else if (result.Code === -1 || result.Code === -2) {
        cogoToast.error(result.Message, { position: "bottom-left" });
      } else if (result.Code === -3) {
        cogoToast.error(result.Message, { position: "bottom-left" });
      } else {
        cogoToast.error(result.Message, { position: "bottom-left" });
      }
    } catch (error) {
      console.log("Something went wrong!")
    }
  };

  const LoginByFacebook = async (data) => {
    ReactGA.event({
      category: "facebook Login",
      action: "click",
      label: "Customer login using email" + email,
    });
    try {
      const name = data.name.split(" ");

      const response = await fetch(Global.API_URL + "Customer/CustomerLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          password: password,
          registrationTypeId: 2,
          firstName: name[0],
          lastName: name[1],
          profilePicture: data.picture.data.url,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const result = await response.json();
      if (result.Code === 0) {
        cogoToast.success(result.Message, { position: "bottom-left" });
        dispatch(
          setCredential({
            isLogged: true,
            guestId: "",
            customerId: result.Response.CustomerId,
            customerToken: result.Response.CustomerToken,
            firstName: result.Response.FirstName,
            lastName: result.Response.LastName,
            email: result.Response.Email,
            purchasedProducts: result.Response.purchasedProducts,
          })
        );
      } else if (result.Code === -1 || result.Code === -2) {
        cogoToast.error(result.Message, { position: "bottom-left" });
      } else if (result.Code === -3) {
        cogoToast.error(result.Message, { position: "bottom-left" });
      } else {
        cogoToast.error(result.Message, { position: "bottom-left" });
      }
    } catch (error) {
      console.log("Something went wrong!")
    }
  };

  function openModal(e) {
    e.preventDefault();
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <li className="login">
      <a href="/" onClick={openModal}>
        <i className="icon-user"></i>Login
      </a>
      {open ? (
        <Modal
          isOpen={open}
          style={customStyles}
          contentLabel="login Modal"
          className="modal-dialog"
          overlayClassName="d-flex align-items-center justify-content-center"
          id="login-modal"
          onRequestClose={closeModal}
          closeTimeoutMS={10}
        >
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">
                  <i className="icon-close"></i>
                </span>
              </button>
              <div className="form-box">
                <div className="form-tab">
                  <Tabs selectedTabClassName="show" defaultIndex={0}>
                    <TabList className="nav nav-pills nav-fill">
                      <Tab className="nav-item">
                        <span className="nav-link">Sign In</span>
                      </Tab>

                      <Tab className="nav-item">
                        <span className="nav-link">Register</span>
                      </Tab>
                    </TabList>

                    <div className="tab-content">
                      <TabPanel style={{ paddingTop: "2rem" }}>
                        <div>
                          <form onSubmit={(e) => Login(e)}>
                            <div className="form-group">
                              <label htmlFor="singin-email-2">
                                Email address *
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="singin-email-2"
                                name="singin-email"
                                value={email}
                                onChange={(e) =>
                                  setEmail(e.target.value.toLowerCase())
                                }
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="singin-password-2">
                                Password *
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="singin-password-2"
                                name="singin-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>

                            <div className="form-footer">
                              <button
                                type="submit"
                                className="btn btn-outline-primary-2 btn-login"
                              >
                                <span>LOG IN</span>
                                <i className="icon-long-arrow-right"></i>
                              </button>

                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="signin-remember-2"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="signin-remember-2"
                                >
                                  Remember Me
                                </label>
                              </div> */}

                              <ALink
                                href="/forgot-password"
                                className="forgot-link"
                              >
                                Forgot Your Password?
                              </ALink>
                            </div>
                          </form>
                          <div className="form-choice">
                            <p className="text-center">or sign in with</p>
                            <div className="row">
                              <div className="col-sm-6">
                                <GoogleLogin
                                  clientId="418748336673-7aod1kvnmn5ehscv8qe5n2kvv2q8at6m.apps.googleusercontent.com"
                                  buttonText="Login With Google"
                                  render={(renderProps) => (
                                    <button
                                      className="btn btn-login btn-g"
                                      onClick={renderProps.onClick}
                                    >
                                      {" "}
                                      <i className="icon-google"></i>Login With
                                      Google
                                    </button>
                                  )}
                                  onSuccess={LoginByGoogle}
                                  cookiePolicy={"single_host_origin"}
                                  icon={true}
                                />
                              </div>
                              <div className="col-sm-6">
                                <FacebookLogin
                                  appId="624122929305557"
                                  fields="name,email,picture"
                                  callback={LoginByFacebook}
                                  cssClass="btn btn-login btn-f"
                                  icon="fa-facebook"
                                  textButton="Login With Facebook"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <form onSubmit={(e) => Register(e)}>
                          <div className="form-group">
                            <label htmlFor="register-password-2">
                              First Name *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="register-password-2"
                              name="register-password"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="register-password-2">
                              Last Name *
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="register-password-2"
                              name="register-password"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="register-email-2">
                              Your email address *
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="register-email-2"
                              name="register-email"
                              value={email}
                              onChange={(e) =>
                                setEmail(e.target.value.toLowerCase())
                              }
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="register-password-2">
                              Password *
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="register-password-2"
                              name="register-password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>

                          <div className="form-footer">
                            <button
                              type="submit"
                              disabled={disable}
                              className="btn btn-outline-primary-2 btn-login"
                            >
                              <span>SIGN UP</span>
                              <i className="icon-long-arrow-right"></i>
                            </button>

                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="register-policy-2"
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="register-policy-2"
                              >
                                I agree to the privacy policy *
                              </label>
                            </div>
                          </div>
                        </form>
                        <div className="form-choice">
                          <p className="text-center">or sign in with</p>
                          <div className="row">
                            <div className="col-md-6">
                              <GoogleLogin
                                clientId="418748336673-7aod1kvnmn5ehscv8qe5n2kvv2q8at6m.apps.googleusercontent.com"
                                buttonText="Login With Google"
                                render={(renderProps) => (
                                  <button className="btn btn-login btn-g">
                                    {" "}
                                    <i className="icon-google"></i>Login With
                                    Google
                                  </button>
                                )}
                                onSuccess={LoginByGoogle}
                                cookiePolicy={"single_host_origin"}
                                icon={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <FacebookLogin
                                appId="624122929305557"
                                fields="name,email,picture"
                                callback={LoginByFacebook}
                                cssClass="btn btn-login btn-f"
                                icon="fa-facebook"
                                textButton="Login With Facebook"
                              />
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </li>
  );
};

export default LoginModal;
