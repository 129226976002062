import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    isLogged: false,
    guestId: "",
    customerId: 0,
    customerToken: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    purchasedProducts: [],
  },
  reducers: {
    setCredential(state, action) {
      const isLogged = action.payload.isLogged;
      
      if (isLogged) {
        return (state = {
          isLogged: true,
          guestId: action.payload.guestId,
          customerId: action.payload.customerId,
          customerToken: action.payload.customerToken,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phoneNumber: action.payload.phoneNumber,
          email: action.payload.email,
          purchasedProducts: action.payload.purchasedProducts,
        });
      }
      if (!isLogged) {
        return (state = {
          isLogged: false,
          guestId: action.payload.guestId,
          customerId: action.payload.customerId,
          customerToken: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          purchasedProducts: [],
        });
      }
    },
  },
});

export const GenerateGuestId = () => {
  return (dispatch) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
};

export const CheckTokenExpiry = (Token, Id) => {
  return async (dispatch) => {
    const response = await fetch(
      Global.API_URL + "Customer/CheckCustomerLoginToken",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
        body: JSON.stringify({
          customerId: Id,
          customerToken: Token,
        }),
      }
    );
    const result = await response.json();
    return result;
  };
};

export const { setCredential } = credentialSlice.actions;
export default credentialSlice.reducer;
