export const Global = {
  // API_URL: "https://smallinsizeapi.vision-more.com/api/",
  // PHOTO_URL: "https://smallinsizeapi.vision-more.com/Uploads/",
  
  // API_URL: "https://api-r.smallinsize.com/api/",
  // PHOTO_URL: "https://api-r.smallinsize.com/Uploads/",

  API_URL: "https://api.smallinsize.com/api/",
  PHOTO_URL: "https://api.smallinsize.com/Uploads/",

  // API_URL: "https://demoapi.smallinsize.com/api/",
  // PHOTO_URL: "https://demoapi.smallinsize.com/Uploads/",
  HostAPI: "*",

  API_SHIP: "https://api.postshipping.com/api2/",
  TRACKING_ID: "G-YZ2BCZG1KM",
  TOKEN: "58FF597E63B5FF8A78D84812F26B534D",
  Payment_Authorization: "SHJNWLZDLT-J2DHW2BBWL-RNJGMKLTT2",
};
