import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addAllToCart(state, action) {
      state.cartItems = action.payload;
    },
    deleteAllFromCart(state) {
      state.cartItems = [];
    },
  },
});

export const GetCartItems = (customerId, guestId, offset, currencyId) => {
  return async (dispatch) => {
    let group = 1;
    if (customerId !== 0) group = 2;
    const response = await fetch(
      Global.API_URL +
        "Cart/GetCartItems/Offset/" +
        offset +
        "/Country/237/Group/" +
        group +
        "/Currency/" +
        currencyId +
        "?CustomerId=" +
        customerId +
        "&GuestId=" +
        guestId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
      }
    );
    if (response.ok) {
      const result = await response.json();
      return result;
    }else{
      return []
    }
  };
};

export const AddToCart = (
  customerId,
  guestId,
  productVariationId,
  quantity
) => {
  return async (dispatch) => {
    try {
      const response = await fetch(Global.API_URL + "Cart/AddItemToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Global.HostAPI,
        },
        body: JSON.stringify({
          customerId: customerId,
          guestId: guestId,
          productVariationId: productVariationId,
          quantity: quantity,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const result = await response.json();

      if (result.Code === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Something went wrong!")
      return false;
    }
  };
};

export const RemoveFromCart = (customerId, guestId, productVariationId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Cart/RemoveItemFromCart/" +
          productVariationId +
          "?CustomerId=" +
          customerId +
          "&GuestId=" +
          guestId,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const result = await response.json();

      if (result.Code === 0) return true;
      else return false;
    } catch (error) {
      console.log("Something went wrong!")
      return false;
    }
  };
};

export const { addAllToCart, deleteAllFromCart } = cartSlice.actions;
export default cartSlice.reducer;
