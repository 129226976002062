import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Global } from "../../../../Global";
import ALink from "../../../features/alink";
import { useState } from "react";
import { setSelectedParent } from "../../../../store/slices/menu-slice";

function MainMenu() {
  let location = useLocation();
  const dispatch = useDispatch();

  const [image, setImage] = useState("/assets/images/minilogo.png");
  const [url, setUrl] = useState("");

  const menu = useSelector((state) => state.menu.menu);
  const selectedParent = useSelector((state) => state.menu.selectedParent);

  let path = location.pathname;
  const ChangeImage = (hoverImage, CategoryId, ItemTitle, ItemId) => {
    setImage(hoverImage);
    if (CategoryId !== 0) {
      setUrl(
        process.env.PUBLIC_URL +
          "/category/" +
          ItemTitle.replace(/ /g, "-") +
          "/" +
          ItemId +
          "/3cols/1"
      );
    } else {
      setUrl(
        process.env.PUBLIC_URL +
          "/brand/" +
          ItemTitle.replace(/ /g, "-") +
          "/" +
          ItemId +
          "/3cols/1"
      );
    }
  };

  const ChangeParent = (index) => {
    dispatch(setSelectedParent(index));
  };

  return (
    <nav className="main-nav">
      <ul className="menu sf-arrows">
        {menu.map((item, firstIndex) => {
          return (
            <li
              key={firstIndex}
              className={selectedParent === firstIndex ? "active" : ""}
              onClick={() => ChangeParent(firstIndex)}
            >
              <ALink
                href={item.Url}
                className={item.MegaMenu ? "sf-with-ul" : "sf-with-ul-m"}
                scroll={false}
              >
                {item.ItemTitle}
              </ALink>

              {item.MegaMenu && (
                <div className="megamenu megamenu-md scrollable-menu">
                  <div className="row no-gutters">
                    <div className="col-md-9">
                      <div className="menu-col">
                        <div className="row">
                          {item.Sub.map((sub, secondIndex) => {
                            return (
                              <div
                                key={secondIndex}
                                className="col-md-4 margin-bottom-20"
                              >
                                <div className="menu-title">
                                  {sub.ItemTitle}
                                </div>
                                <ul>
                                  {sub.Sub.map((subsub, thirdIndex) => {
                                    return (
                                      <li
                                        key={thirdIndex}
                                        onMouseOver={() => {
                                          subsub.Image === ""
                                            ? setImage(
                                                "/assets/images/minilogo.png"
                                              )
                                            : ChangeImage(
                                                Global.PHOTO_URL + subsub.Image,
                                                subsub.CategoryId,
                                                subsub.ItemTitle,
                                                subsub.ItemId
                                              );
                                        }}
                                        onMouseOut={() =>
                                          setImage(
                                            "/assets/images/minilogo.png"
                                          )
                                        }
                                        onClick={() => ChangeParent(firstIndex)}
                                        className={
                                          path.indexOf(
                                            subsub.ItemTitle.replace(/ /g, "-")
                                          ) > -1
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <ALink
                                          href={
                                            subsub.CategoryId !== 0
                                              ? process.env.PUBLIC_URL +
                                                "/category/" +
                                                subsub.ItemTitle.replace(
                                                  / /g,
                                                  "-"
                                                ) +
                                                "/" +
                                                subsub.ItemId +
                                                "/3cols/1"
                                              : process.env.PUBLIC_URL +
                                                "/brand/" +
                                                subsub.ItemTitle.replace(
                                                  / /g,
                                                  "-"
                                                ) +
                                                "/" +
                                                subsub.ItemId +
                                                "/3cols/1"
                                          }
                                          scroll={false}
                                        >
                                          {subsub.ItemTitle}
                                        </ALink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="banner banner-overlay">
                        <ALink
                          href={
                            image === "/assets/images/minilogo.png" ? "/" : url
                          }
                          className="banner banner-menu"
                        >
                          <img src={image} alt="Banner" />

                          {/* <div className="banner-content banner-content-top">
                            <div className="banner-title text-white">
                              Last <br />
                              Chance
                              <br />
                              <span>
                                <strong>Sale</strong>
                              </span>
                            </div>
                          </div> */}
                        </ALink>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default MainMenu;
