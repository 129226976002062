import { Global } from "../../Global";
import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const addressSlice = createSlice({
  name: "address",
  initialState: {
    countries: [],
    addressBook: [],
  },
  reducers: {
    setAddress(state, action) {
      state.addressBook = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
  },
});

export const getCountries = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Country/GetAllPublishedCountries",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log("Something went wrong!")
    }
  };
};

export const getCities = (countryId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Country/GetCitiesByCountryCode/" + countryId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log("Something went wrong!")
    }
  };
};
export const GetAddresses = (customerId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "AddressBook/GetAddressBooks/" + customerId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log("Something went wrong!")
    }
  };
};

export const { setAddress, setCountries } = addressSlice.actions;
export default addressSlice.reducer;
