import React from "react";
import { Link } from "react-router-dom";

const alink = ({ children, className, style, href, hideAngle }) => {
  return (
    <Link to={href} className={className} style={style}>
      {children}
    </Link>
  );
};

export default alink;
