import { useRef } from "react";

function StickyHeader(props) {
  const ref = useRef(null);

  return (
    <div ref={ref} className="sticky-wrapper" style={{ height: "auto" }}>
      {props.children}
    </div>
  );
}

export default StickyHeader;
