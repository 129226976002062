import { Global } from "../../Global";

const { createSlice } = require("@reduxjs/toolkit");

const menuSlice = createSlice({
  name: "address",
  initialState: {
    selectedParent: 0,
    menu: [],
  },
  reducers: {
    setMenu(state, action) {
      state.menu = action.payload;
    },

    setSelectedParent(state, action) {
      state.selectedParent = action.payload;
    },
  },
});

export const GetMenu = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        Global.API_URL + "Menus/GetAssignedItemsFrontend/8",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();

      return result;
    } catch (error) {
      console.log("Something went wrong!")
    }
  };
};

export const { setMenu, setSelectedParent } = menuSlice.actions;
export default menuSlice.reducer;
