import React, { Fragment, useEffect, useState } from "react";
import Reveal from "react-awesome-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { Global } from "../Global";
import {
  setBrands,
  setCategories,
  setSlides,
} from "../store/slices/widget-slice";
import cogoToast from "cogo-toast";
import { hideMobileMenu } from "../utils";

import Layout from "../layouts/Layout";
import ALink from "../components/features/alink";
import OwlCarousel from "../components/features/owl-carousel";
import TrendyCollection from "../components/partials/home/trendy-collection";
import NewCollection from "../components/partials/home/new-collection";
import ReactGA from "react-ga";
import NewsletterModal from "../components/features/modals/newsletter-modal";

import {
  introSlider,
  brandSlider,
  fadeInLeftShorter,
  fadeInRightShorter,
  fadeIn,
} from "../utils/data";

const Home = () => {
  const dispatch = useDispatch();
  const { slides, categories, brands } = useSelector((state) => state.widget);
  const [sliderData, setSliderData] = useState(slides);
  const [categoryData, setCategoryData] = useState(
    categories.length === 5 ? categories : []
  );
  const [brandData, setBrandData] = useState(brands);

  const [email, setEmail] = useState("");

  const isValid =
    email.trim() !== "" && email.includes("@") && email.includes(".");

  const Subscribe = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "NewsLetter Subscribe  ",
      action: "Click",
      label: email,
    });

    if (!isValid) {
      cogoToast.error("Enter a valid email", { position: "bottom-left" });
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "NewsLetter/SubscribeToNewsLetter",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const result = await response.json();

        cogoToast.success(result, {
          position: "bottom-left",
        });
        setEmail("");
      } catch (error) {
        console.log("Something went wrong!")
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    hideMobileMenu();
    ReactGA.pageview(window.location.pathname + window.location.search);
    const fetchSlider = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Slider/GetSlidesBySliderId/1",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const result = await response.json();
        setSliderData(result[0].slides);
        dispatch(setSlides(result[0].slides));
      } catch (error) {
        console.log("Something went wrong!")
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "HomePage/GetHomePageCategories",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const result = await response.json();
        setCategoryData(result);
        dispatch(setCategories(result));
      } catch (error) {
        console.log("Something went wrong!")
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "BrandPage/GetBrandsForSwiper",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const result = await response.json();
        setBrandData(result);
        dispatch(setBrands(result));
      } catch (error) {
        console.log("Something went wrong!")
      }
    };

    fetchSlider();
    fetchCategories();
    fetchBrands();
  }, [dispatch]);

  return (
    <Layout>
      <main className="main home-page skeleton-body">
        <div className="intro-slider-container">
          <OwlCarousel
            adClass="intro-slider owl-theme owl-nav-inside owl-light"
            options={introSlider}
            type="mainSlider"
          >
            {sliderData.map((slide, index) => {
              return (
                <img src={Global.PHOTO_URL + slide.SlideImage} alt="slide" key={index} /> 
                // <div
                //   key={index}
                //   className="intro-slide"
                //   style={{
                //     backgroundImage:
                //       "url(" + Global.PHOTO_URL + slide.SlideImage + ")",
                //   }}
                // ></div>
              );
            })}
          </OwlCarousel>
        </div>

        {categoryData.length !== 0 && (
          <div className="pt-2 pb-3">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 text-center">
                  <Reveal
                    keyframes={fadeInLeftShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "First Widget : " + categoryData[0].WidgetTitle,
                          action: "Click",
                          label: categoryData[0].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[0].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[0].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[0].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>
                    </div>
                  </Reveal>
                  <div className="margin-bottom-4">
                    <h3 className="banner-title">
                      <ALink
                        href={
                          "/category/" +
                          categoryData[0].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[0].categories[0].CategoryId +
                          "/3cols/1"
                        }
                      >
                        <strong>
                          {categoryData[0].categories[0].CategoryName}
                        </strong>
                      </ALink>
                    </h3>
                    <ALink
                      href={
                        "/category/" +
                        categoryData[0].categories[0].CategoryName.replace(
                          / /g,
                          "-"
                        ) +
                        "/" +
                        categoryData[0].categories[0].CategoryId +
                        "/3cols/1"
                      }
                      className="btn btn-outline-white banner-link underline d-block d-sm-none"
                    >
                      Shop Now
                    </ALink>
                  </div>
                </div>

                {/* <div className="col-6 d-block d-sm-none text-center">
                  <Reveal
                    keyframes={fadeInLeftShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "Third Widget : " + categoryData[2].WidgetTitle,
                          action: "Click",
                          label: categoryData[2].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[2].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[2].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[2].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>
                    </div>
                  </Reveal>
                  <div className="margin-bottom-4">
                    <h3 className="banner-title">
                      <ALink
                        href={
                          "/category/" +
                          categoryData[2].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[2].categories[0].CategoryId +
                          "/3cols/1"
                        }
                      >
                        <strong>
                          {categoryData[2].categories[0].CategoryName}
                        </strong>
                      </ALink>
                    </h3>
                    <ALink
                      href={
                        "/category/" +
                        categoryData[2].categories[0].CategoryName.replace(
                          / /g,
                          "-"
                        ) +
                        "/" +
                        categoryData[2].categories[0].CategoryId +
                        "/3cols/1"
                      }
                      className="btn btn-outline-white banner-link underline d-block d-sm-none"
                    >
                      Shop Now
                    </ALink>
                  </div>
                </div>

                <div className="col-6 d-block d-sm-none text-center">
                  <Reveal
                    keyframes={fadeInLeftShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "Fourth Widget : " + categoryData[3].WidgetTitle,
                          action: "Click",
                          label: categoryData[3].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[3].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[3].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[3].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>
                    </div>
                  </Reveal>
                  <div className="margin-bottom-4">
                    <h3 className="banner-title">
                      <ALink
                        href={
                          "/category/" +
                          categoryData[3].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[3].categories[0].CategoryId +
                          "/3cols/1"
                        }
                      >
                        <strong>
                          {categoryData[3].categories[0].CategoryName}
                        </strong>
                      </ALink>
                    </h3>
                    <ALink
                      href={
                        "/category/" +
                        categoryData[3].categories[0].CategoryName.replace(
                          / /g,
                          "-"
                        ) +
                        "/" +
                        categoryData[3].categories[0].CategoryId +
                        "/3cols/1"
                      }
                      className="btn btn-outline-white banner-link underline d-block d-sm-none"
                    >
                      Shop Now
                    </ALink>
                  </div>
                </div>

                <div className="col-6 d-block d-sm-none text-center">
                  <Reveal
                    keyframes={fadeInLeftShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "Fifth Widget : " + categoryData[4].WidgetTitle,
                          action: "Click",
                          label: categoryData[4].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[4].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[4].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[4].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>
                    </div>
                  </Reveal>
                  <div className="margin-bottom-4">
                    <h3 className="banner-title">
                      <ALink
                        href={
                          "/category/" +
                          categoryData[4].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[4].categories[0].CategoryId +
                          "/3cols/1"
                        }
                      >
                        <strong>
                          {categoryData[4].categories[0].CategoryName}
                        </strong>
                      </ALink>
                    </h3>
                    <ALink
                      href={
                        "/category/" +
                        categoryData[4].categories[0].CategoryName.replace(
                          / /g,
                          "-"
                        ) +
                        "/" +
                        categoryData[4].categories[0].CategoryId +
                        "/3cols/1"
                      }
                      className="btn btn-outline-white banner-link underline d-block d-sm-none"
                    >
                      Shop Now
                    </ALink>
                  </div>
                </div> */}

                <div className="col-sm-6 text-center">
                  <Reveal
                    keyframes={fadeInRightShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "Second Widget : " + categoryData[1].WidgetTitle,
                          action: "Click",
                          label: categoryData[1].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[1].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[1].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[1].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>
                    </div>
                    <div className="margin-bottom-4">
                      <h3 className="banner-title">
                        <ALink
                          href={
                            "/category/" +
                            categoryData[1].categories[0].CategoryName.replace(
                              / /g,
                              "-"
                            ) +
                            "/" +
                            categoryData[1].categories[0].CategoryId +
                            "/3cols/1"
                          }
                        >
                          <strong>
                            {categoryData[1].categories[0].CategoryName}
                          </strong>
                        </ALink>
                      </h3>
                      <ALink
                        href={
                          "/category/" +
                          categoryData[1].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[1].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="btn btn-outline-white banner-link underline d-block d-sm-none"
                      >
                        Shop Now
                      </ALink>
                    </div>
                  </Reveal>
                </div>
              </div>
              <hr className="mt-0 mb-0" />
            </div>
          </div>
        )}

        <div className="mb-5"></div>
        <Reveal keyframes={fadeIn} delay={100} duration={1000} triggerOnce>
          <TrendyCollection />
        </Reveal>

        <div className="mb-5"></div>

        <div className="pt-5 pb-3" style={{ backgroundColor: "#ececec" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6 col-6">
                <Reveal
                  keyframes={fadeInRightShorter}
                  delay={100}
                  duration={1000}
                  triggerOnce
                >
                  <div className="icon-box text-center">
                    <span className="icon-box-icon">
                      <i className="icon-truck"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Payment & Delivery</h3>
                      <p>Free shipping for orders over $50</p>
                    </div>
                  </div>
                </Reveal>
              </div>

              <div className="col-lg-3 col-sm-6 col-6">
                <Reveal
                  keyframes={fadeInRightShorter}
                  delay={100}
                  duration={1000}
                  triggerOnce
                >
                  <div className="icon-box text-center">
                    <span className="icon-box-icon">
                      <i className="icon-rotate-left"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Return & Refund</h3>
                      <p>Free 100% money back guarantee</p>
                    </div>
                  </div>
                </Reveal>
              </div>

              <div className="col-lg-3 col-sm-6 col-6">
                <Reveal
                  keyframes={fadeInLeftShorter}
                  delay={100}
                  duration={1000}
                  triggerOnce
                >
                  <div className="icon-box text-center">
                    <span className="icon-box-icon">
                      <i className="icon-unlock"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Secure Payment</h3>
                      <p>100% secure payment</p>
                    </div>
                  </div>
                </Reveal>
              </div>

              <div className="col-lg-3 col-sm-6 col-6">
                <Reveal
                  keyframes={fadeInLeftShorter}
                  delay={100}
                  duration={1000}
                  triggerOnce
                >
                  <div className="icon-box text-center">
                    <span className="icon-box-icon">
                      <i className="icon-headphones"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Quality Support</h3>
                      <p>Alway online feedback 24/7</p>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-6"></div>
        <Reveal keyframes={fadeIn} delay={100} duration={1000} triggerOnce>
          <NewCollection />
        </Reveal>

        <div className="pb-3">
          <Reveal keyframes={fadeIn} delay={100} duration={1000} triggerOnce>
            <div className="container brands pt-5 pt-lg-7">
              <h2 className="title text-center margin-bottom-4">
                shop by brands
              </h2>
              <Reveal keyframes={fadeIn} delay={100} duration={500} triggerOnce>
                <OwlCarousel
                  adClass="brands-border owl-simple brand-carousel mt-3 margin-bottom-3 cols-xl-7 cols-lg-5 cols-md-4 cols-sm-3 cols-2"
                  options={brandSlider}
                  type=""
                >
                  {brandData.map((brand, index) => (
                    <ALink
                      href={
                        "/brand/" +
                        brand.BrandName.trim().replace(/ /g, "-") +
                        "/" +
                        brand.BrandId +
                        "/3cols/1"
                      }
                      className="brand mr-0"
                      key={index}
                    >
                      <img
                        src={Global.PHOTO_URL + brand.Image}
                        onClick={() =>
                          ReactGA.event({
                            category: "Shop by Brand ",
                            action: "Click",
                            label: brand.BrandName,
                          })
                        }
                        alt="brand"
                        width={100}
                        height={50}
                      />
                    </ALink>
                  ))}
                </OwlCarousel>
              </Reveal>
            </div>
          </Reveal>

          <div className="mb-5 mb-lg-7"></div>

          <div className="container newsletter">
            <div className="row">
              {/* {categoryData.length !== 0 && (
                <div className="col-lg-6 banner-overlay-div">
                  <Reveal
                    keyframes={fadeInLeftShorter}
                    delay={100}
                    duration={1000}
                    triggerOnce
                  >
                    <div
                      className="banner banner-overlay"
                      onClick={() =>
                        ReactGA.event({
                          category:
                            "Third Widget : " + categoryData[2].WidgetTitle,
                          action: "Click",
                          label: categoryData[2].categories[0].CategoryName,
                        })
                      }
                    >
                      <ALink
                        href={
                          "/category/" +
                          categoryData[2].categories[0].CategoryName.replace(
                            / /g,
                            "-"
                          ) +
                          "/" +
                          categoryData[2].categories[0].CategoryId +
                          "/3cols/1"
                        }
                        className="lazy-media"
                      >
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                          alt="banner"
                          src={
                            Global.PHOTO_URL +
                            categoryData[2].categories[0].CategoryImage
                          }
                          threshold={500}
                          width="376"
                          height="auto"
                          effect="blur"
                        />
                      </ALink>

                      <div className="banner-content banner-content-center">
                        <h3 className="banner-title text-white">
                          <ALink
                            href={
                              "/category/" +
                              categoryData[2].categories[0].CategoryName.replace(
                                / /g,
                                "-"
                              ) +
                              "/" +
                              categoryData[2].categories[0].CategoryId +
                              "/3cols/1"
                            }
                          >
                            <strong>
                              {categoryData[2].categories[0].CategoryName}
                            </strong>
                          </ALink>
                        </h3>
                        <ALink
                          href={
                            "/category/" +
                            categoryData[2].categories[0].CategoryName.replace(
                              / /g,
                              "-"
                            ) +
                            "/" +
                            categoryData[2].categories[0].CategoryId +
                            "/3cols/1"
                          }
                          className="btn btn-outline-white banner-link underline"
                        >
                          Shop Now
                        </ALink>
                      </div>
                    </div>
                  </Reveal>
                </div>
              )} */}

              <div className="col-12 d-flex align-items-stretch subscribe-div">
                <div className="cta cta-box">
                  <div className="cta-content">
                    <Reveal
                      keyframes={fadeInRightShorter}
                      delay={100}
                      duration={1000}
                      triggerOnce
                    >
                      <Fragment>
                        <h3 className="cta-title">
                          Subscribe To Our Newsletter
                        </h3>
                        <p>
                          {/* Sign up now for{" "}
                          <span className="primary-color">10% discount</span> on
                          first order. Customise my news: */}
                          Ensure that you stay up to date with the latest news
                          and information by following us on your favorite
                          social media platform.
                        </p>

                        <form onSubmit={(e) => Subscribe(e)}>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your Email Address"
                            aria-label="Email Adress"
                            value={email}
                            onChange={(e) =>
                              setEmail(e.target.value.toLowerCase())
                            }
                            required
                          />
                          <div className="text-center">
                            <button
                              className="btn btn-outline-dark-2"
                              type="submit"
                            >
                              <span>subscribe</span>
                            </button>
                          </div>
                        </form>
                      </Fragment>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <NewsletterModal />
    </Layout>
  );
};

export default Home;
