import React, { useEffect, useState } from "react";
import { Global } from "../../../Global";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

import ALink from "../../features/alink";
import ProductTwelve from "../../features/products/product-twelve";

function NewCollection() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [widgetName, setWidgetName] = useState("");
  const [widgetUrl, setWidgetUrl] = useState("");
  const isLogged = useSelector((state) => state.credential.isLogged);
  const { currencyId } = useSelector((state) => state.currency);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const fetchProducts = async () => {
      const offset = new Date().getTimezoneOffset();
      let groupId = 2;
      if (!isLogged) {
        groupId = 1;
      }

      try {
        const response = await fetch(
          Global.API_URL +
            "Widgets/GetProductsByWidgetId/10/Offset/" +
            offset +
            "/Country/237/GroupId/" +
            groupId +
            "/Currency/" +
            currencyId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const result = await response.json();
        setWidgetName(result.WidgetName);
        setWidgetUrl(result.WidgetUrl);
        setProducts(result.Products);
        setIsLoading(false);
      } catch (error) {
        console.log("Something went wrong!")
      }
    };
    fetchProducts();
  }, [isLogged, currencyId]);

  return (
    <div className="container">
      <h2 className="title text-center margin-bottom-4">{widgetName}</h2>

      <div className="products">
        <div className="row justify-content-center">
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                <div className="col-6 col-md-4 col-lg-3" key={item}>
                  <div className="skel-pro"></div>
                </div>
              ))
            : window.innerWidth > 767
            ? products.slice(0, 10).map((product, index) => {
                return (
                  <div className="col-6 col-md-4 col-lg-3" key={index}>
                    <ProductTwelve product={product} />
                  </div>
                );
              })
            : products.slice(0, 8).map((product, index) => {
                return (
                  <div className="col-6 col-md-4 col-lg-3" key={index}>
                    <ProductTwelve product={product} />
                  </div>
                );
              })}
        </div>
      </div>
      <div className="more-container text-center mt-2">
        {widgetUrl !== "" && (
          <ALink href={widgetUrl} className="btn btn-more">
            <span>show more</span>
          </ALink>
        )}
      </div>
    </div>
  );
}

export default NewCollection;
