import React from "react";
import { useSelector } from "react-redux";

import ALink from "../../../features/alink";

function WishlistMenu() {
  const wishlist = useSelector((state) => state.wishlist.wishlistItems).length;

  return (
    <ALink href="/wishlist" className="wishlist-link" title="Wishlist">
      <i className="icon-heart-o"></i>
      <span className="wishlist-count">{wishlist}</span>
      <span className="wishlist-txt">My Wishlist</span>
    </ALink>
  );
}

export default WishlistMenu;
